@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
  Note: The styles for this gradient grid background is heavily inspired by the creator of this amazing site (https://dub.sh) – all credits go to them! 
*/

body{
  background-color: #F8F8F8;
}

.main {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main:before {
  background: radial-gradient(circle, rgba(2, 0, 36, 0) 0, #fafafa 100%);
  position: absolute;
  content: "";
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
}

.main:after {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.4;
  filter: invert(1);
}

.gradient {
  height: fit-content;
  z-index: 3;
  width: 100%;
  max-width: 640px;
  background-image: radial-gradient(
      at 27% 37%,
      hsla(215, 98%, 61%, 1) 0px,
      transparent 0%
    ),
    radial-gradient(at 97% 21%, hsla(125, 98%, 72%, 1) 0px, transparent 50%),
    radial-gradient(at 52% 99%, hsla(354, 98%, 61%, 1) 0px, transparent 50%),
    radial-gradient(at 10% 29%, hsla(256, 96%, 67%, 1) 0px, transparent 50%),
    radial-gradient(at 97% 96%, hsla(38, 60%, 74%, 1) 0px, transparent 50%),
    radial-gradient(at 33% 50%, hsla(222, 67%, 73%, 1) 0px, transparent 50%),
    radial-gradient(at 79% 53%, hsla(343, 68%, 79%, 1) 0px, transparent 50%);
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  filter: blur(100px) saturate(150%);
  top: 80px;
  opacity: 0.15;
}

@media screen and (max-width: 640px) {
  .main {
    padding: 0;
  }
}

/* Tailwind Styles */

.app {
  @apply relative z-10 flex justify-center items-center flex-col max-w-7xl mx-auto sm:px-16 px-6;
}

.black_btn {
  @apply rounded-full border border-black bg-black py-1.5 px-5 text-white transition-all hover:bg-white hover:text-black text-center text-sm flex items-center justify-center;
}

.outline_btn {
  @apply rounded-full border border-black bg-transparent py-1.5 px-5 text-black transition-all hover:bg-black hover:text-white text-center text-sm flex items-center justify-center;
}

.head_text {
  @apply mt-5 text-5xl font-extrabold leading-[1.15] text-black sm:text-6xl;
}

.orange_gradient {
  @apply bg-gradient-to-r from-amber-500 via-orange-600 to-yellow-500 bg-clip-text text-transparent;
}

.green_gradient {
  @apply bg-gradient-to-r from-green-400 to-green-500 bg-clip-text text-transparent;
}

.blue_gradient {
  @apply bg-gradient-to-r from-blue-600 to-cyan-600 bg-clip-text text-transparent;
}

.desc {
  @apply mt-5 text-lg text-gray-600 sm:text-xl max-w-2xl;
}

.search_input {
  @apply block w-full rounded-md border border-gray-200 bg-white py-2.5 font-satoshi pl-5 pr-12 text-sm shadow-lg font-medium focus:border-black focus:outline-none focus:ring-0;
}

.copy_btn {
  @apply w-7 h-7 rounded-full bg-white/10 shadow-[inset_10px_-50px_94px_0_rgb(199,199,199,0.2)] backdrop-blur flex justify-center items-center cursor-pointer;
}

.glassmorphism {
  @apply rounded-xl border border-gray-200 bg-white/20 shadow-[inset_10px_-50px_94px_0_rgb(199,199,199,0.2)] backdrop-blur p-5;
}

.prompt_layout {
  @apply space-y-6 py-8 sm:columns-2 sm:gap-6 xl:columns-3;
}

/* Feed Component */
.feed {
  @apply mt-16 mx-auto w-full max-w-xl flex justify-center items-center flex-col gap-2;
}

/* Form Component */
.form_textarea {
  @apply w-full flex rounded-lg h-[200px] mt-2 p-3 text-sm text-gray-500 outline-0;
}

.form_input {
  @apply w-full flex rounded-lg mt-2 p-3 text-sm text-gray-500 outline-0;
}

/* Nav Component */
.logo_text {
  @apply max-sm:hidden font-satoshi font-semibold text-lg text-black tracking-wide;
}

.dropdown {
  @apply absolute right-0 top-full mt-3 w-full p-5 rounded-lg bg-white min-w-[210px] flex flex-col gap-2 justify-end items-end;
}

.dropdown_link {
  @apply text-sm font-inter text-gray-700 hover:text-gray-500 font-medium;
}

/* PromptCard Component */
.prompt_card {
  @apply flex-1 break-inside-avoid rounded-lg border border-gray-300 bg-white/20 bg-clip-padding p-6 pb-4 backdrop-blur-lg backdrop-filter md:w-[360px] w-full h-fit;
}

/* Flex Properties */

.flex-center {
  @apply flex justify-center items-center;
}

.flex-start {
  @apply flex justify-start items-start;
}

.flex-end {
  @apply flex justify-end items-center;
}

.flex-between {
  @apply flex justify-between items-center;
}

.flex-around {
  @apply flex justify-around items-center;
}

.flex-evenly {
  @apply flex justify-evenly items-center;
}
/* Page Container */
.page-container {
  @apply w-screen h-[100%] flex-center xs:flex-col gap-5 md:flex-row light-gray-bg  pt-10 pb-20 md:px-20;
}

/* modal */
.modal-overlay {
  @apply fixed top-0 left-0 w-screen h-screen flex-center bg-black/50 z-[1];
}

.modal {
  @apply absolute top-[10%] xs:w-[90%] md:w-[35%] xs:max-h-[96vh]  p-5 !z-[2] flex-center flex-col rounded-[2rem] bg-white;
}

.modal-header {
  @apply font-bold pb-[20px];
}

.modal-inputs-container {
  @apply w-full flex flex-col gap-3 overflow-auto;
}

.modal-close-button {
  @apply absolute right-3 top-1 p-1 text-[#0079bf] hover:border hover:rounded-full border-transparent hover:bg-[#F8F8F8];
}

/* checkout sections */

.section-container {
  @apply w-full py-5 px-5 flex-center flex-col gap-5 list-none bg-white;
}

/* LISTS */

.admin-sidebar-li {
  @apply w-full flex gap-1 list-none p-1 hover:bg-[#F8F8F8] cursor-pointer hover:border hover:rounded-md;
}

.custom-li {
  @apply p-4 list-none border-b-[1px] border-b-[#EBEBEB] hover:bg-[#0079bf] cursor-pointer hover:text-white;
}

.order-billing-li {
  @apply flex;
}

/* Colors */
.light-gray-bg {
  @apply bg-[#F8F8F8];
}

.medium-gray-bg {
  @apply bg-[#EBEBEB];
}

.darker-gray-bg {
  @apply bg-[#B3B3B3];
}

.blue-bg {
  @apply bg-[#0079bf];
}

.orange-bg {
  @apply bg-[#ff9f1a];
}

.border-color {
  @apply border-[#e9eaed];
}

/* text */

.responsive-text {
  @apply xs:text-[13px] md:text-[13px];
}

.billing-li-details {
  @apply w-[65%] flex justify-between;
}

.billing-li-label {
  @apply w-[35%];
}

.client-detail-label {
  @apply text-[14px] font-bold;
}

/* 
Trello Lochmara
Hex: #0079bf
RGB: 0, 121, 191

Trello Limeade
Hex: #70b500
RGB: 112, 181, 0

Trello Tree Poppy
Hex: #ff9f1a
RGB: 255, 159, 26

Trello Burnt Sienna
Hex: #eb5a46
RGB: 235, 90, 70

Trello School bus Yellow
Hex: #f2d600
RGB: 242, 214, 0

Trello Lavender
Hex: #c377e0
RGB: 195, 119, 224

Trello Hot Pink
Hex: #ff78cb
RGB: 255, 120, 203

Trello Robin's Egg Blue
Hex: #00c2e0
RGB: 0, 194, 224

Trello Shamrock
Hex: #51e898
RGB: 81, 232, 152

Trello Silver Sand
Hex: #c4c9cc
RGB: 196, 201, 204 */
